import type { App } from 'vue'
import { createRouter, createWebHistory } from 'vue-router/auto'
import { handleHotUpdate, routes } from 'vue-router/auto-routes'
import { setupLayouts } from 'virtual:generated-layouts'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false })

export function install(app: App) {
  const router = createRouter({
    history: createWebHistory(),
    routes: setupLayouts(routes),
  })

  router.beforeEach(() => {
    NProgress.start()
  })

  router.afterEach(() => {
    NProgress.done()
  })

  app.use(router)

  // This will update routes at runtime without reloading the page
  if (import.meta.hot) {
    handleHotUpdate(router)
  }
}
