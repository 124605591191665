export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/app/src/pages/index.vue'),
    /* no children */
    meta: {
      "layout": "app"
    }
  },
  {
    path: '/:catch(.*)',
    name: '/[...catch]',
    component: () => import('/app/src/pages/[...catch].vue'),
    /* no children */
  },
  {
    path: '/character',
    /* internal name: '/character' */
    /* no component */
    children: [
      {
        path: 'dialogue',
        name: '/character/dialogue',
        component: () => import('/app/src/pages/character/dialogue.vue'),
        /* no children */
        meta: {
          "layout": "app"
        }
      },
      {
        path: 'label',
        name: '/character/label',
        component: () => import('/app/src/pages/character/label.vue'),
        /* no children */
        meta: {
          "layout": "app"
        }
      },
      {
        path: 'theater',
        name: '/character/theater',
        component: () => import('/app/src/pages/character/theater.vue'),
        /* no children */
        meta: {
          "layout": "app"
        }
      }
    ],
  },
  {
    path: '/chat',
    /* internal name: '/chat' */
    /* no component */
    children: [
      {
        path: 'history',
        name: '/chat/history',
        component: () => import('/app/src/pages/chat/history.vue'),
        /* no children */
        meta: {
          "layout": "app"
        }
      },
      {
        path: 'memory',
        name: '/chat/memory',
        component: () => import('/app/src/pages/chat/memory.vue'),
        /* no children */
        meta: {
          "layout": "app"
        }
      }
    ],
  },
  {
    path: '/misc',
    /* internal name: '/misc' */
    /* no component */
    children: [
      {
        path: 'feedback',
        name: '/misc/feedback',
        component: () => import('/app/src/pages/misc/feedback.vue'),
        /* no children */
        meta: {
          "layout": "app"
        }
      },
      {
        path: 'user',
        name: '/misc/user',
        component: () => import('/app/src/pages/misc/user.vue'),
        /* no children */
        meta: {
          "layout": "app"
        }
      }
    ],
  },
  {
    path: '/payment',
    /* internal name: '/payment' */
    /* no component */
    children: [
      {
        path: 'order',
        name: '/payment/order',
        component: () => import('/app/src/pages/payment/order.vue'),
        /* no children */
        meta: {
          "layout": "app"
        }
      }
    ],
  },
  {
    path: '/picture',
    /* internal name: '/picture' */
    /* no component */
    children: [
      {
        path: 'album',
        name: '/picture/album',
        component: () => import('/app/src/pages/picture/album.vue'),
        /* no children */
        meta: {
          "layout": "app"
        }
      },
      {
        path: 'prompt',
        name: '/picture/prompt',
        component: () => import('/app/src/pages/picture/prompt.vue'),
        /* no children */
        meta: {
          "layout": "app"
        }
      },
      {
        path: 'task',
        name: '/picture/task',
        component: () => import('/app/src/pages/picture/task.vue'),
        /* no children */
        meta: {
          "layout": "app"
        }
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

