import { createApp } from 'vue'

// css @layer
import 'uno:preflights.css' // uno:preflights
import 'uno.css' // uno:base
import '~/assets/style/index.css' // reset, primevue, layout, base
import 'uno:default.css' // uno:default

import App from './App.vue'

const app = createApp(App)

const modules = import.meta.glob<{ install: (ctx: typeof app) => void }>('./modules/*.ts', { eager: true })
Object.values(modules).forEach(i => app.use(i))

app.mount('#app')
